<template>
  <v-card>
    <v-card-title>
      获取GoRDG Endpoints列表
    </v-card-title>
    <v-card-subtitle>
      <p>获取连接到GoRDG的所有Endpoint的列表。</p>
      <v-btn
        :color="staticConfig.themeConfig.basePrimaryColor"
        text
        @click="getEndpoints"
      >
        获取Endpoints列表
      </v-btn>
    </v-card-subtitle>
    <v-card-text>
      <v-list
        class="overflow-y-auto overflow-x-hidden"
        max-height="60vh"
      >

        <v-list-group
          v-for="(item, i) in listItems"
          :key="i"
          v-model="item.active"
          prepend-icon="mdi-switch"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.childs"
            :key="child.name"
          >
            <v-list-item-icon>
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
              <v-list-item-subtitle v-text="child.value" />
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-icon>
                <v-icon
                  small
                  @click="copyValue(child.value)"
                >mdi-content-copy</v-icon>
              </v-list-item-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card-text>
    <v-dialog
      v-model="dialogWait"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        :color="staticConfig.themeConfig.waitDialogBgColor"
        dark
      >
        <v-card-text>
          请稍后......
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogInfo"
      hide-overlay
      width="300"
    >
      <v-alert
        tile
        :type="typeOfDialogInfo"
      >
        {{ textOfDialogInfo }}
      </v-alert>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    listItems: [],
    dialogWait: false,
    dialogInfo: false,
    textOfDialogInfo: "",
    typeOfDialogInfo: "",
  }),
  methods: {
    getEndpoints() {
      var url = "/api/gordg-api/get-endpoints";
      var authJwt = this.zllauth.GetJwt();
      if (!authJwt) {
        return;
      }
      if (!authJwt.success) {
        return;
      }
      var pcf = {
        headers: {
          "ZLLAuth-Token": authJwt.token,
        },
      };
      this.listItems = [];
      this.dialogWait = true;
      this.axios
        .get(url, pcf)
        .then(
          (resp) => {
            if (resp.data.response) {
              try {
                var jdat = JSON.parse(resp.data.response);
                this.listItems = [];
                for (var i in jdat) {
                  var ji = jdat[i];
                  console.debug("LINE", ji);
                  var vals = [
                    {
                      title: "标识符",
                      value: ji.name,
                      icon: "mdi-identifier",
                    },
                    {
                      title: "地址",
                      value: ji.address,
                      icon: "mdi-ip-network-outline",
                    },
                  ];
                  if (ji.meta) {
                    if (ji.meta.comment) {
                      vals.push({
                        title: "备注",
                        value: ji.meta.comment,
                        icon: "mdi-comment-text-outline",
                      });
                    }
                    if (ji.meta.name) {
                      vals.push({
                        title: "Token记名",
                        value: ji.meta.name,
                        icon: "mdi-pen",
                      });
                    }
                    if (ji.meta.iss) {
                      vals.push({
                        title: "Token签发者",
                        value: ji.meta.iss,
                        icon: "mdi-account-key-outline",
                      });
                    }
                    if (ji.meta.exp) {
                      var exptm = new Date(ji.meta.exp * 1000);
                      vals.push({
                        title: "Token有效期至",
                        value: exptm.toString(),
                        icon: "mdi-clock-time-three-outline",
                      });
                    }
                    if (ji.meta.rdg_endpoint) {
                      vals.push({
                        title: "RDG Endpoint URL",
                        value: ji.meta.rdg_endpoint,
                        icon: "mdi-link-variant",
                      });
                    }
                    if (ji.meta.uuid) {
                      vals.push({
                        title: "UUID",
                        value: ji.meta.uuid,
                        icon: "mdi-alpha-u-circle-outline",
                      });
                    }
                    if (ji.meta.rtt) {
                      vals.push({
                        title: "RTT",
                        value: ji.meta.rtt,
                        icon: "mdi-sort-clock-ascending-outline",
                      });
                    }
                  }
                  this.listItems.push({
                    title: ji.name,
                    childs: vals,
                  });
                }
                this.dialogWait = false;
              } catch (err) {
                this.dialogWait = false;
                this.textOfDialogInfo = "获取失败，详见浏览器控制台输出";
                this.typeOfDialogInfo = "error";
                this.dialogInfo = true;
                console.warn("Failed parse JSON", err);
              }
            } else {
              this.dialogWait = false;
              this.textOfDialogInfo = "获取失败，详见浏览器控制台输出";
              this.typeOfDialogInfo = "error";
              this.dialogInfo = true;
              console.warn("Failed get endpoints", resp);
            }
          },
          (err) => {
            this.dialogWait = false;
            this.textOfDialogInfo = "获取失败，详见浏览器控制台输出";
            this.typeOfDialogInfo = "error";
            this.dialogInfo = true;
            console.warn("Failed get endpoints", err);
          }
        )
        .catch((err) => {
          this.dialogWait = false;
          this.textOfDialogInfo = "获取失败，详见浏览器控制台输出";
          this.typeOfDialogInfo = "error";
          this.dialogInfo = true;
          console.warn("Failed get endpoints", err);
        });
    },
    copyValue(value) {
      console.log("emucopy", value);
      this.$copyText(value)
        .then(() => {
          this.textOfDialogInfo = "已复制";
          this.typeOfDialogInfo = "success";
          this.dialogInfo = true;
        })
        .catch((err) => {
          this.textOfDialogInfo = "复制失败，详见浏览器控制台输出";
          this.typeOfDialogInfo = "error";
          this.dialogInfo = true;
          console.warn("Failed to copy to clipboard", err);
        });
    },
  },
};
</script>